<template>
    <div class="container signup">
		<div class="logo">
			<img src="@/assets/logo.png" alt="logo">
		</div>

		<form @submit.prevent="register" class="d-flex flex-column mt-5">

			<input type="text" class="input-field mb-4" name="email" v-model="formData.email" placeholder="Email">

			<input type="text" class="input-field mb-4" name="nama" v-model="formData.name" placeholder="Nama">
			
			<input type="text" class="input-field mb-4" name="phone" v-model="formData.phone" placeholder="Nomor HP">

			<input type="password" class="input-field mb-5" name="password" v-model="formData.password" placeholder="Password">

			<button type="submit" class="button mb-4">SIGNUP</button>
		</form>

		<p class="text-center">Sudah punya akun? <router-link to="/login">Login</router-link></p>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	data() {
		return {
			formData: {
				email: '',
				name: '',
				phone: '',
				password: ''
			}
		}
	},
	methods: {
		register() {
			axios.post(`auth/register`, this.formData)
				.then(res => {
					localStorage.setItem('token', res.data.token);

					this.$router.push('/');
				})
				.catch(err => {
					console.log(err.response.data);
				}); 
		}
	},
}
</script>

<style scoped>

.container {
	width: 90%;
}

.logo {
	margin: 20px 0;
	display: flex;
	justify-content: center;
}

.logo img {
	width: 70%;
}

.signup p a {
	color: black;
	font-weight: 500;
}

button {
	color: white;
}

</style>